import '@usb-shield/react-grid/dist/library/styles/index.css'
import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import React from 'react'
import SolutionsAreaOverviewTile from '@/components/SolutionsAreaOverview/SolutionsAreaOverviewTile'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import saoStyle from '@/components/SolutionsAreaOverview/solutionsAreaOverview.module.scss'
import { useMediaQuery } from '@/utils/usb-media-query.util'
import {
  SolutionsAreaOverviewModel,
  GetOverviewImageParams,
} from '@/components/SolutionsAreaOverview/SolutionsAreaOverview.model'
import USBLink from '@usb-shield/react-link'
import { getOverviewImage } from '@/components/SolutionsAreaOverview/utils/imageUtils'

const fourColsOption = {
  span: null,
  spans: {
    large: 4,
    medium: 4,
    small: 4,
  },
  offset: {
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const textColumn = {
  ...twelveColsOption,
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
  },
}

const imageWithoutTitleColumn = {
  ...fourColsOption,
  spans: {
    xlarge: 6,
    large: 8,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  align: 'center',
  justify: 'end',
}

const solAreaWithoutTitleColumn = {
  ...fourColsOption,
  spans: {
    xlarge: 6,
    large: 8,
    medium: 4,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}

const SolutionsImageLeftVertText = ({
  solutionArea,
}: {
  solutionArea: SolutionsAreaOverviewModel
}) => {
  const domain: string = process.env.CONTENT_DOMAIN || '',
    imageSrc = domain.concat(solutionArea.image || '')

  let isPageWide = useMediaQuery('(min-width: 760px) and (max-width: 1055px)'),
    isMobileDevice = useMediaQuery('(min-width: 250px) and (max-width: 759px)'),
    video = solutionArea.video || '',
    usbLinkAvailable =
      solutionArea.ctaLink &&
      solutionArea.ctaLink.length > 0 &&
      solutionArea.ctaText &&
      solutionArea.ctaText.length > 0,
    getImageParams: GetOverviewImageParams = {
      isPageWide: isPageWide,
      isMobileDevice: isMobileDevice,
      imageSrc: imageSrc,
      videoSrc: video,
    },
    img = getOverviewImage(getImageParams)

  return (
    <section id="SolutionsImageLeftVertText" className={saoStyle.sectionRow}>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        addClasses={saoStyle.gridWrapper}
      >
        <USBColumn layoutOpts={textColumn}>
          <h2
            className={saoStyle.headH2}
            data-testid="solutionOverviewHeading"
            dangerouslySetInnerHTML={{ __html: solutionArea.title }}
          ></h2>
          <p
            className={saoStyle.paragraph}
            dangerouslySetInnerHTML={{ __html: solutionArea.shortDescription }}
            data-testid="solutionOverviewSubHeading"
          ></p>
        </USBColumn>
        <USBColumn
          layoutOpts={imageWithoutTitleColumn}
          addClasses={saoStyle.overviewImageNoTitle}
        >
          {img}
        </USBColumn>
        <USBColumn
          layoutOpts={solAreaWithoutTitleColumn}
          addClasses={saoStyle.overviewListWithoutTitle}
        >
          {/* Add SolutionsAreaOverviewTile */}
          <SolutionsAreaOverviewTile
            tiles={solutionArea.textBlockWithImageParts}
            addClasses={saoStyle.overviewListWithoutTitle}
            levelUpHeads={!solutionArea.title}
          />

          {usbLinkAvailable && (
            <USBLink
              linkType="arrow"
              href={solutionArea.ctaLink}
              dataTestId={`props-ctaLink`}
              addClasses={saoStyle.solutionLink}
            >
              {solutionArea.ctaText}
            </USBLink>
          )}
        </USBColumn>
      </USBGrid>
    </section>
  )
}

export default SolutionsImageLeftVertText
